import cloneDeep from "lodash/cloneDeep";
import { cmsAssetUrl, cmsUrl } from "./CmsUtils";
import { productIsUnavailable } from "./ProductUtils";
import { getCurrentSite } from "./SiteUtils";
import { getAllProductCategoryDescendants } from "./ProductCategoryUtils";
import find from "lodash/find";
import filter from "lodash/filter";
import cleanDeep from "clean-deep";

export function filterEntryLang(_entry, lang) {
  const entry = cloneDeep(_entry);
  if (lang) {
    for (let field of Object.keys(entry)) {
      const matches = field.match(new RegExp(`(.*)_${lang}$`));
      if (matches) {
        entry[matches[1]] = entry[field];
      }
    }
  }
  return entry;
}

export function removeDisabledLayoutItems(item) {
  if (item === null) {
    return null;
  } else if (Array.isArray(item)) {
    return item.map(removeDisabledLayoutItems).filter(i => i != null);
  } else if (typeof item === "object") {
    if (item?.settings?.disabled === true) {
      return null;
    }
    delete item?.settings?.disabled;
    for (let key of Object.keys(item)) {
      const value = item[key];
      if (
        value != null &&
        (Array.isArray(value) || typeof value === "object")
      ) {
        const filtered = removeDisabledLayoutItems(value);
        if (filtered === null) {
          delete item[key];
        } else {
          item[key] = filtered;
        }
      }
    }
  }
  return item;
}

export function unwrapAsset(image) {
  if (image === null || image?.path === undefined) return image;
  const ret = { ...image };
  ret.path = cmsAssetUrl(image.path);
  if (Array.isArray(image.styles)) {
    ret.styles = image.styles.reduce((map, style) => {
      map[style.style] = cmsUrl(style.path);
      return map;
    }, {});
  }
  return ret;
}

export function unwrapRepeater(items) {
  return items ? items.map(item => item.value) : [];
}

export function unwrapImageStyles(image) {
  if (image && Array.isArray(image.styles)) {
    image.styles = image.styles.reduce((map, style) => {
      map[style.style] = cmsUrl(style.path);
      return map;
    }, {});
  }
  return image;
}

export function unwrapField(field) {
  return data => data[field];
}

export function enhanceProduct(entry) {
  entry.specifications = entry.specs ? [...entry.specs] : [];
  delete entry.specs;

  if (Array.isArray(entry.images)) {
    entry.images = entry.images.map(unwrapImageStyles);
  }
  if (Array.isArray(entry.tags)) {
    entry.tags = entry.tags.map(tag => tag.name || tag.display);
  } else {
    entry.tags = [];
  }
  if (productIsUnavailable(entry)) {
    entry.price = {};
  }

  if (!entry.categories) {
    console.warn("Missing categories", entry);
    entry.categories = [];
  }

  return entry;
}

export function validateShopProduct(product) {
  let valid = true;
  if (product?.price?.basePrice === "0") {
    console.debug("Product missing price:", product.sku, product);
    valid = false;
  }
  if (!product.parcelType) {
    console.debug("Product missing parcelType:", product.sku, product);
    valid = false;
  }
  return valid;
}

export function enhanceAccessories(products) {
  if (Array.isArray(products)) {
    const accessoriesMap = products.reduce((map, { sku, accessories }) => {
      if (Array.isArray(accessories)) {
        accessories.forEach(acc => {
          if (map[acc] === undefined) map[acc] = [];
          map[acc].push(sku);
        });
      }
      return map;
    }, {});
    console.debug("accessoriesMap", accessoriesMap);
    products = products.map(p => ({
      ...p,
      accessoriesReversed: accessoriesMap[p.sku] || []
    }));
  }
  return products;
}

export function enhanceStory(entry) {
  if (entry.featured_image) {
    entry.featured_image.path = cmsAssetUrl(entry.featured_image.path);
    entry.featured_image = unwrapImageStyles(entry.featured_image);
  }
  if (Array.isArray(entry.images)) {
    entry.images = entry.images
      .map(unwrapImageStyles)
      .map(image => ({ ...image, path: cmsUrl(image.path) }));
  }
  if (Array.isArray(entry.parts)) {
    entry.parts = entry.parts.map(part =>
      part.component === "story_image"
        ? {
            ...part,
            settings: {
              ...part.settings,
              image: unwrapAsset(part.settings.image)
            }
          }
        : part
    );
  }
  return entry;
}

export function enhanceGuide(entry) {
  if (Array.isArray(entry.tags)) {
    entry.tags = entry.tags.map(tag => tag.name || tag.display);
  } else {
    entry.tags = [];
  }
  return entry;
}

export function unwrapGuideCategories(cats = []) {
  if (Array.isArray(cats)) {
    cats = cats.reduce((map, cat) => {
      map[cat._id] = cat;
      return map;
    }, {});
  }
  return cats;
}

export function enhanceNews(item) {
  const image = unwrapImageStyles(item.image);
  image.path = cmsAssetUrl(image.path);
  item.media = Array.isArray(item.media)
    ? item.media.map(({ asset }) => asset)
    : [];
  item.media.forEach(media => {
    unwrapImageStyles(media);
    media.path = cmsAssetUrl(media.path);
  });
  if (Array.isArray(item.contactPeople))
    item.contactPeople.forEach(person => {
      const image = unwrapImageStyles(person.image);
      if (image) {
        image.path = cmsAssetUrl(image.path);
      }
    });
  return item;
}

export function enhancePeople(entry) {
  if (entry.featured_image) {
    entry.featured_image.path = cmsAssetUrl(entry.featured_image.path);
    entry.featured_image = unwrapImageStyles(entry.featured_image);
  }
  return entry;
}

export function enhanceClaimsData(_entry = {}) {
  const entry = cloneDeep(_entry);
  if (Array.isArray(entry.types)) {
    entry.types.forEach(type => {
      if (!type.kam) {
        delete type.kam;
      }
      type.extra_fields = type.extra_fields || [];
      type.requirements = type.requirements || [];
      type.sites = type.sites || [];
    });
  }
  return cleanDeep(entry, {
    emptyArrays: false,
    emptyObjects: false,
    emptyStrings: false,
    NaNValues: false,
    nullValues: true,
    undefinedValues: true
  });
}

const cockpitCollectionPreviewEvent = "cockpit:collections.preview";

export function cockpitCollectionPreviewDataListener(event) {
  if (event.data && event.data.event === cockpitCollectionPreviewEvent) {
    window.localStorage.setItem(event.data.event, JSON.stringify(event.data));
  }
}

export function applyCockpitCollectionPreviewData(listener = () => {}) {
  const previousData = localStorage.getItem(cockpitCollectionPreviewEvent);
  if (previousData) {
    listener({ data: JSON.parse(previousData) });
  }
}

export function enhanceSettingsProductCategories(
  settings,
  onlyEnabledOnCurrentSite = true
) {
  // prepare to remove subtrees that are not active on the current site
  const disabledCategories = [];
  if (onlyEnabledOnCurrentSite) {
    settings.product_categories.forEach(category => {
      if (category.sites && category.sites.indexOf(getCurrentSite()) < 0) {
        disabledCategories.push(category._id);
        getAllProductCategoryDescendants(
          category._id,
          settings.product_categories
        ).forEach(child => {
          disabledCategories.push(child._id);
        });
      }
    });
  }
  // build enabled categories
  const enabledCategories = [];
  const filteredProductCategories = settings.product_categories.filter(
    category => disabledCategories.indexOf(category._id) < 0
  );
  for (const category of filteredProductCategories) {
    enabledCategories.push({
      ...category,
      hidden: category.hidden === true,
      //TODO should use _id directly and not key field
      key: category._id,
      short_key: category.name.split("/").pop()
    });
  }
  //generate hierarchy references from enabled categories
  enabledCategories.forEach(category => {
    category.parent = find(enabledCategories, { _id: category._pid });
    category.children = filter(enabledCategories, { _pid: category._id });
  });
  settings.product_categories = enabledCategories;
  return settings;
}
