import { useMemo } from "react";
import { AnimatePresence, LayoutGroup, motion } from "motion/react";
import classNames from "classnames";
import "./style.scss";
import Icon from "../Icon";
import { useUiMessageContext } from "./context";
import ConfirmDialog from "../ConfirmDialog";
import {
  UiMessage,
  UiMessageButton,
  UiMessageType
} from "../../types/UiMessage";
import BjelinButton from "../BjelinButton";
import { useBjelinTranslations } from "../../hooks/TranslationHooks";
import { CircularProgress } from "../Progress";

export const MSG_DELAY = 3000;
export const INFO = "info";
export const SUCCESS = "success";
export const WARN = "warn";
export const ERROR = "error";

export function getIcon(type?: UiMessageType): string {
  switch (type) {
    case INFO:
      return "circle-info";
    case SUCCESS:
      return "circle-check";
    case WARN:
      return "triangle-exclamation";
    case ERROR:
      return "hand";
    default:
      return "circle-info";
  }
}

const UIMessage = (message: UiMessage) => {
  const { dismissMessage } = useUiMessageContext();

  const t = useBjelinTranslations();
  const key = useMemo(
    () => message.identifier || Math.random().toString(),
    [message.identifier]
  );

  const buttons = useMemo(() => {
    const extraButtons: UiMessageButton[] = [];
    if (message.link_url) {
      extraButtons.push({
        text: t("ui-message.read-more"),
        url: message.link_url,
        type: "secondary"
      });
    }
    if (message.persist) {
      extraButtons.push({
        text: t("ui-message.dismiss"),
        onClick: () => dismissMessage(key),
        type: "secondary",
        className: "dismiss-btn"
      });
    }
    return (message.buttons || []).concat(extraButtons);
  }, [
    message.link_url,
    message.persist,
    message.buttons,
    t,
    dismissMessage,
    key
  ]);

  const MessageButton = (button: UiMessageButton) => {
    return (
      <BjelinButton
        key={button.text}
        onClick={button.onClick}
        url={button.url}
        color={
          button.type === "dangerous"
            ? "danger"
            : button.type === "primary"
            ? "primary"
            : "secondary"
        }
        size={"small"}
        className={button.className}
      >
        {button.icon && button.iconPosition === "before" && (
          <span className={"button-icon before"}>{button.icon}</span>
        )}
        {button.text}
        {button.icon && button.iconPosition === "after" && (
          <span className={"button-icon after"}>{button.icon}</span>
        )}
      </BjelinButton>
    );
  };

  if (!message.persist) {
    setTimeout(() => {
      dismissMessage(key);
    }, message.delay || MSG_DELAY);
  }

  return (
    <motion.div
      layout={true}
      key={message.identifier}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, scale: 0.75, y: -20, transition: { duration: 0.25 } }}
      className={classNames("uiMessage", {
        [SUCCESS]: message.type === SUCCESS,
        [WARN]: message.type === WARN,
        [ERROR]: message.type === ERROR,
        [INFO]: message.type === INFO
      })}
      data-msgcode={message.code}
    >
      <div className="msg-content">
        {message.icon ? (
          <div className="msg-icon">{message.icon}</div>
        ) : (
          <Icon className="msg-icon" name={getIcon(message.type)} />
        )}
        <div className={"msg-text"}>{message.text}</div>
      </div>

      <div className="msg-buttons">
        {buttons &&
          buttons.length > 0 &&
          buttons.map(button => (
            <MessageButton key={button.text} {...button} />
          ))}
        {!message.persist && (
          <div className={"msg-progress"}>
            <CircularProgress
              progress={100}
              duration={Math.max(
                (message.delay || MSG_DELAY / 1000) - 0.5,
                0.5
              )}
            />
          </div>
        )}
      </div>
    </motion.div>
  );
};

const UIMessages = () => {
  const {
    getMessages,
    getActiveConfirmDialog,
    confirmActiveDialog,
    dismissActiveDialog
  } = useUiMessageContext();

  const activeConfirmDialog = getActiveConfirmDialog();

  return (
    <>
      <div className="uiMessages" id="ui-messages">
        <LayoutGroup>
          <AnimatePresence>
            {getMessages()
              .filter(msg => !msg.banner)
              .map(msg => (
                <UIMessage {...msg} key={msg.identifier} />
              ))}
          </AnimatePresence>
        </LayoutGroup>
      </div>
      {activeConfirmDialog && (
        <ConfirmDialog
          message={activeConfirmDialog}
          confirm={confirmActiveDialog}
          dismiss={dismissActiveDialog}
        />
      )}
    </>
  );
};

export default UIMessages;
