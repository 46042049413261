import classNames from "classnames";
import { useHistory } from "react-router-dom";
import Icon from "../Icon";
import "./style.scss";
import PropTypes from "prop-types";

const BjelinButton = ({
  id,
  className,
  style,
  color,
  url,
  onClick,
  icon,
  icon_position = "after",
  children,
  lg = true,
  block = false,
  loading,
  disabled = false,
  disabledColor,
  disableTabFocus = false,
  title = "",
  subtitle = "",
  outline = false,
  size = "large"
}) => {
  const history = useHistory();
  if (loading) {
    icon = "spinner";
    color = disabledColor || "bjelin-grey";
  }
  if (disabled) {
    color = disabledColor || "bjelin-grey";
  }
  let iconBefore,
    iconAfter = null;
  if (icon) {
    if (icon_position === "before") {
      iconBefore = (
        <>
          <Icon name={icon} className={icon === "spinner" ? "fa-pulse" : ""} />{" "}
        </>
      );
    } else {
      iconAfter = (
        <>
          {" "}
          <Icon name={icon} className={icon === "spinner" ? "fa-pulse" : ""} />
        </>
      );
    }
  }
  let outlineClass = outline ? "outline-" : "";

  /** Backwards compatibility */
  if (lg === false && size === "large") {
    size = "standard";
  }

  const classes = classNames(
    "btn",
    {
      "btn-lg": size === "large",
      "btn-sm": size === "small",
      "btn-block": block
    },
    "btn-" + outlineClass + color,
    { "has-subtitle": subtitle },
    "bjelin-button",
    className
  );
  if (url) {
    onClick = () => {
      if (
        url.startsWith("mailto:") ||
        url.startsWith("tel:") ||
        /^https?:\/\//i.test(url)
      ) {
        window.location.href = url;
      } else {
        history.push(url);
      }
    };
  }
  return (
    <button
      id={id}
      className={classes}
      style={style}
      disabled={disabled}
      tabIndex={disableTabFocus ? -1 : 0}
      title={title}
      onClick={e =>
        !disabled && !loading && typeof onClick === "function" && onClick(e)
      }
    >
      {iconBefore}
      {children}
      {iconAfter}
      {subtitle && <div className="subtitle">{subtitle}</div>}
    </button>
  );
};

const colors = [
  "primary",
  "secondary",
  "success",
  "danger",
  "warning",
  "info",
  "light",
  "dark",
  "muted",
  "white",
  "black",
  "grey",
  "bjelin-green",
  "bjelin-brown",
  "bjelin-grey",
  "bjelin-middlegrey",
  "bjelin-darkgrey",
  "bjelin-orange",
  "link"
];

BjelinButton.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.oneOf(colors),
  url: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.string,
  icon_position: PropTypes.oneOf(["before", "after"]),
  lg: PropTypes.bool, //deprecated, use size instead
  block: PropTypes.bool,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  disabledColor: PropTypes.oneOf(colors),
  disableTabFocus: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  outline: PropTypes.bool,
  size: PropTypes.oneOf(["large", "small", "standard"])
};

export default BjelinButton;
