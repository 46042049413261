import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState
} from "react";
import { useLocation } from "react-router";
import { Language } from "../config/languages";
import { Site } from "../config/sites";

type PathLanguage = Language | "all";

type NavigationContext = {
  lang: Language;
  pathLang: Language;
  setAvailableOnSites: (sites: Site[]) => void;
  pageAvailableOnSites: Site[] | undefined;
  currentRegisteredPaths: Partial<Record<PathLanguage, string>>;
  registerCurrentPaths: (paths: Partial<Record<PathLanguage, string>>) => void;
};

const navigationContext = createContext<NavigationContext>(
  {} as NavigationContext
);

export const NavigationContextProvider = ({
  lang,
  pathLang,
  children
}: {
  lang: Language;
  pathLang: Language;
  children: ReactNode;
}) => {
  const [currentPaths, setCurrentPaths] = useState<
    Record<string, Record<string, string>>
  >({});
  const [availableOnSites, _setAvailableOnSites] = useState<
    Record<string, Site[]>
  >({});
  const location = useLocation();

  const registerCurrentPaths = useCallback(
    (paths: Record<string, string> | undefined) => {
      console.log("registerCurrentPaths", location.pathname, paths);
      setCurrentPaths(state => {
        return {
          ...state,
          [location.pathname]: paths || {}
        };
      });
    },
    [setCurrentPaths, location.pathname]
  );

  const setAvailableOnSites = useCallback(
    (sites: Site[]) => {
      console.log("setAvailableOnSites", location.pathname, sites);
      _setAvailableOnSites(state => {
        return {
          ...state,
          [location.pathname]: sites || []
        };
      });
    },
    [_setAvailableOnSites, location.pathname]
  );

  const currentRegisteredPaths = useMemo(
    () => currentPaths[location.pathname] || {},
    [currentPaths, location.pathname]
  );

  const pageAvailableOnSites = useMemo(
    () => availableOnSites?.[location.pathname],
    [availableOnSites, location.pathname]
  );

  return (
    <navigationContext.Provider
      value={{
        lang,
        pathLang,
        currentRegisteredPaths,
        registerCurrentPaths,
        pageAvailableOnSites,
        setAvailableOnSites
      }}
    >
      {children}
    </navigationContext.Provider>
  );
};

export const useNavigationContext = () => useContext(navigationContext);
