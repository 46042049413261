import axios from "axios";

export async function getBuildVersion(): Promise<string> {
  return new Promise(resolve => {
    axios
      .get("/version")
      .then(({ data }) => resolve(data))
      .catch(() => resolve("unknown"));
  });
}
