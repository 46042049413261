import { ProductCategory } from "../types/ProductCategory";
import filter from "lodash/filter";
import intersection from "lodash/intersection";
import uniq from "lodash/uniq";
import uniqBy from "lodash/uniqBy";
import { Product } from "../types/Product";
import { useMemo } from "react";
import { Language, languages } from "../config/languages";
import { shopUrl } from "../config/urls";
import { TFunction } from "i18next";
import { useProductCategories } from "../hooks/ProductCategoryHooks";
import { Site, sites } from "../config/sites";

export const OUTLET_CATEGORY_ID = "61518aafe7c8a64ef30ad102";
export const CONCEPT_CATEGORY_ID = "64768e1fe2f8e644420b3772";

/**
 * Oldest ancestor is first in list and self last
 * @param category
 */
export function getProductCategoryPedigree(
  category?: ProductCategory
): ProductCategory[] {
  if (category) {
    const result = [category];
    let parent = category.parent;
    while (parent) {
      result.unshift(parent);
      parent = parent.parent;
    }
    return result;
  }
  return [];
}

/**
 * All descendants in breadth-first order
 * @param _id
 * @param productCategories
 */
export function getAllProductCategoryDescendants(
  _id: string,
  productCategories: ProductCategory[]
): ProductCategory[] {
  const recurse = (
    _id: string,
    productCategories: ProductCategory[]
  ): ProductCategory[] => {
    let children = filter(productCategories, { _pid: _id });
    for (const child of children) {
      children = children.concat(recurse(child._id, productCategories));
    }
    return children;
  };
  const result = recurse(_id, productCategories);
  return result;
}

export function productCategoryPath(
  category?: ProductCategory,
  lang?: string,
  delimiter = "--"
): string | undefined {
  if (category) {
    const paths = [
      lang
        ? category.paths[lang] || category.paths["en"] || category.path
        : category.path
    ];
    let parent = category.parent;
    while (parent) {
      paths.unshift(
        lang
          ? parent.paths[lang] || parent.paths["en"] || parent.path
          : parent.path
      );
      parent = parent.parent;
    }
    return paths.join(delimiter);
  } else {
    return undefined;
  }
}

/**
 * Preceded with /shop/...
 * @param category
 * @param lang (optional)
 */
export function productCategoryUrl(
  category?: ProductCategory,
  lang?: string
): string | undefined {
  const path = productCategoryPath(category, lang, "--");
  return path ? `${shopUrl}/${path}` : undefined;
}

export function productCategoryUrls(
  category?: ProductCategory
): Record<Language, string> {
  return Object.fromEntries(
    languages.map(lang => [lang, productCategoryUrl(category, lang)])
  ) as Record<Language, string>;
}

export function useCategoryFromPath(
  categoryPath: string
): ProductCategory | undefined {
  const categories = useProductCategories();
  return useMemo(
    () => categoryFromPath(categoryPath, categories),
    [categoryPath, categories]
  );
}

/**
 * @param categoryPath - with or without shopUrl prefix
 * @param categories
 */
export function categoryFromPath(
  categoryPath: string,
  categories: ProductCategory[]
): ProductCategory | undefined {
  if (!categoryPath) return undefined;
  const shopPrefix = new RegExp(`^${shopUrl}/`);
  const pathLookup = Object.fromEntries(
    categories.map(category => [
      productCategoryUrl(category)?.replace(shopPrefix, ""),
      category
    ])
  );
  const lookupPath = categoryPath.replace(shopPrefix, "");
  let result = pathLookup[lookupPath];
  if (!result) {
    const otherPathsLookup = Object.fromEntries(
      categories.flatMap(category =>
        Object.entries(category.paths).map(([lang, _path]) => [
          productCategoryUrl(category, lang)?.replace(shopPrefix, ""),
          category
        ])
      )
    );
    result = otherPathsLookup[lookupPath];
  }
  return result;
}

export function categoriesByProduct(
  product?: Product,
  categories?: ProductCategory[]
): ProductCategory[] {
  const result: ProductCategory[] = [];
  if (product) {
    for (const category of categories || []) {
      if (
        product?.categories &&
        product.categories.indexOf(category._id) >= 0
      ) {
        result.push(category);
      }
    }
    if (result.length === 0) {
      console.warn(
        "No category found for product with categories",
        product?.categories
      );
    }
  }
  return result;
}

export function useCategoriesByProduct(product?: Product): ProductCategory[] {
  const categories = useProductCategories();
  return useMemo(
    () => categoriesByProduct(product, categories),
    [product, categories]
  );
}

export function useCategoryByProduct(
  product?: Product
): ProductCategory | undefined {
  const categories = useCategoriesByProduct(product);
  return product && categories.length > 0 ? categories[0] : undefined;
}

function getDescendantIds(
  category: ProductCategory,
  thatOnlyIncludesProducts = true
): string[] {
  let result: string[] = [];
  category.children.forEach(child => {
    if (!thatOnlyIncludesProducts || child.hide_products_in_parent !== true) {
      result.push(child._id);
      result = result.concat(getDescendantIds(child, thatOnlyIncludesProducts));
    }
  });
  return result;
}

export function filterProductsByCategory(
  products: Product[],
  category: ProductCategory,
  includeFromSubcategories: boolean
): Product[] {
  if (!includeFromSubcategories) {
    return products.filter(
      product => (product?.categories || []).indexOf(category._id) >= 0
    );
  } else {
    const categoryIds: string[] = [category._id, ...getDescendantIds(category)];
    let resultWithDuplicates: Product[] = [];
    categoryIds.forEach(categoryId => {
      resultWithDuplicates = resultWithDuplicates.concat(
        products.filter(p => (p.categories || []).indexOf(categoryId) >= 0)
      );
    });
    return uniqBy(resultWithDuplicates, "_id");
  }
}

export function productCategoryHasParent(
  category?: ProductCategory,
  parent?: ProductCategory,
  includeSelf = false
): boolean {
  if (category) {
    let _parent = includeSelf ? category : category.parent;
    while (_parent) {
      if (_parent._id === parent?._id) {
        return true;
      }
      _parent = _parent.parent;
    }
  }
  return false;
}

export function categorySubtreeHasProducts(
  category?: ProductCategory,
  products?: Product[],
  considerProductInclusion = false
): boolean {
  if (category && products) {
    const categoryIds = [
      category._id,
      ...getDescendantIds(category, considerProductInclusion)
    ];
    for (const product of products) {
      const matchingCategories = intersection(product.categories, categoryIds);
      if (matchingCategories.length > 0) {
        return true;
      }
    }
    return false;
  } else {
    return false;
  }
}

export function productCategoryTitle(
  category: ProductCategory,
  tp: TFunction
): string {
  return category.title || tp("categories." + category.short_key);
}

export function categoryIsListable(
  category?: ProductCategory,
  products?: Product[],
  considerProductInclusion = false
) {
  return (
    category &&
    !category.hidden &&
    (!products ||
      categorySubtreeHasProducts(category, products, considerProductInclusion))
  );
}

export function hasOutletCategory(product?: Product) {
  return (
    product &&
    product.categories &&
    product.categories.indexOf(OUTLET_CATEGORY_ID) >= 0
  );
}

export function hasConceptCategory(product?: Product) {
  return (
    product &&
    product.categories &&
    product.categories.indexOf(CONCEPT_CATEGORY_ID) >= 0
  );
}

export function ecommerceCategories(
  product?: Product,
  categories?: ProductCategory[]
): string[] {
  const productCategories = categoriesByProduct(product, categories);
  const paths = productCategories.flatMap(category =>
    productCategoryPath(category, "en", "--")?.split("--")
  );
  return uniq(paths.filter((path): path is string => !!path));
}

export function isVisibleOnSite(
  category: ProductCategory,
  site: string
): boolean {
  const pedigree = getProductCategoryPedigree(category);
  for (const cat of pedigree) {
    const sites: string[] = (cat as never)["sites"];
    if (cat.hidden || (sites && sites.indexOf(site) < 0)) return false;
  }
  return true;
}

export function visibleOnSites(category?: ProductCategory): Site[] | undefined {
  return category
    ? sites.filter(site => isVisibleOnSite(category, site))
    : undefined;
}
