import {
  convertHtmlToReact,
  convertNodeToReactElement
} from "@hedgedoc/html-to-react";
import { Link } from "react-router-dom";
import Icon from "../components/Icon";
import cloneDeep from "lodash/cloneDeep";
import { cdnBaseUrl } from "../config/urls";

export function cockpitEditUrl(collection, _id, lang) {
  if (lang === "en") {
    lang = "";
  }
  const url = `https://cms.bjelin.io/collections/entry/${collection}`;
  return (_id ? url + `/${_id}` : url) + `?lang=${lang}`;
}

export function cmsUrl(url) {
  return typeof url === "string" && url.match(/^\/[^/]/)
    ? cdnBaseUrl + url.replace(/^\/storage\//, "/")
    : url;
}

export function getSlug(slug) {
  return typeof slug === "string"
    ? slug
        .replace(new RegExp("[.:!,;\\-/\\\\=?@&=+*$<>#%~ ]+", "g"), "-")
        .toLowerCase()
    : "";
}

export function cmsAssetUrl(url) {
  return typeof url === "string" && url.match(/^\/[^/]/)
    ? `${cdnBaseUrl}/uploads${url}`
    : url;
}

export function isCMSImage(image) {
  return typeof image.cimgt === "number" && typeof image.path === "string";
}

export function replaceIcons(html) {
  html = html || "";
  const replacements = {
    "chevron-left": { needle: "&lsaquo;" },
    "chevron-right": { needle: "&rsaquo;" },
    "arrow-left": { needle: "&larr;" },
    "arrow-right": { needle: "&rarr;" },
    copyright: { needle: "&copy;", size: "2xs" },
    trademark: { needle: "&trade;", size: "2xs" },
    registered: { needle: "&reg;", size: "2xs" }
  };
  for (let [icon, conf] of Object.entries(replacements)) {
    const pattern = new RegExp(conf.needle, "gi");
    const size = conf.size ? ` data-size="${conf.size}"` : "";
    html = html.replace(pattern, `<icon name="${icon}" ${size}></icon>`);
  }
  return html;
}

function transform(node, index, externalizeLinks = true) {
  // fix img src to use cms url
  if (node.type === "tag" && node.name === "img" && node.attribs.src) {
    node.attribs.src = cmsUrl(node.attribs.src);
    // console.log("img", node);
  }
  if (node.type === "tag" && node.name === "a" && node.attribs.href) {
    const href = node.attribs.href;
    if (href.startsWith("/")) {
      // convert relative a tags to Link
      delete node.attribs.href;
      fixStringStyle(node);
      return (
        <Link to={href} key={index} {...node.attribs}>
          {node.children.map(transform)}
        </Link>
      );
    } else if (
      externalizeLinks &&
      /^https?:\/\/.*/i.test(href) &&
      href.indexOf(window.location.origin) === -1
    ) {
      // add icon and target to external link
      fixStringStyle(node);
      return (
        <a key={index} {...node.attribs} target={"_blank"}>
          {node.children.map(transform)}{" "}
          <Icon name="arrow-up-right-from-square" />
        </a>
      );
    }
  }
  if (node.type === "tag" && node.name === "icon" && node.attribs.name) {
    return (
      <Icon
        key={index}
        name={node.attribs.name}
        size={node.attribs["data-size"]}
      />
    );
  }
  return convertNodeToReactElement(node, index, transform);
}

function fixStringStyle(node) {
  if (typeof node.attribs.style == "string") {
    node.attribs.style = { cssText: node.attribs.style.replace(/;$/, "") };
  }
}

export function transformHtml(rawHtml) {
  if (!rawHtml) {
    return rawHtml;
  }
  let html = replaceIcons(rawHtml);
  return convertHtmlToReact(replaceIcons(html), { transform });
}

export function addBreadcrumbs(immutablePage) {
  const pathParts = immutablePage.path.replace(/^\//, "").split("/");
  if (pathParts.length > 1) {
    const page = cloneDeep(immutablePage);
    const breadcrumb = {
      component: "_breadcrumb",
      settings: {
        paths: [],
        title: page.title,
        class: "",
        id: "",
        style: ""
      }
    };
    let p = "";
    for (let i = 0; i < pathParts.length - 1; i++) {
      p += "/" + pathParts[i];
      breadcrumb.settings.paths.push(p);
    }
    page.content.splice(1, 0, breadcrumb);
    return page;
  }
  return immutablePage;
}

export function findFirstHeading(data) {
  //console.log("findFirstHeading", data);
  if (data.settings.heading) return data.settings.heading;
  if (data.component === "heading" || data.component === "bjelin_heading")
    return data.settings.text;
  if (data.component === "text" || data.component === "bjelin_text") {
    if (data.settings.text) {
      const match = data.settings.text.match(/<h[\d][^>]*>(.*?)<\/h[\d]>/);
      if (match) return match[1];
    }
  }
  if (data.component === "columns")
    return (
      data.settings.column_0.map(c => findFirstHeading(c)).find(c => !!c) ||
      data.settings.column_1.map(c => findFirstHeading(c)).find(c => !!c)
    );
  if (data.children)
    return data.children.map(c => findFirstHeading(c)).find(c => !!c);
  return undefined;
}
