/**
 * A singleton for storing the user secrets
 */
let secrets = undefined;

const Singleton = {
  setSecrets: newVal => (secrets = newVal),
  getDeferredSecrets: async maxWait => {
    return new Promise(resolve => {
      const timeout = 100;
      let i = 0;
      let loop = setInterval(() => {
        if (secrets || i++ * timeout >= maxWait) {
          if (!secrets && maxWait > 0) {
            console.error(`Could not get secrets after ${i} tries`);
          }
          clearInterval(loop);
          resolve(secrets);
        }
      }, timeout);
    });
  }
};

// Disallow new properties
Object.freeze(Singleton);

export default Singleton;
