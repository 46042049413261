import { captureException, withScope } from "@sentry/react";
import PropTypes from "prop-types";
import { Component, isValidElement } from "react";

/**
 * Custom ErrorBoundary component for catching specific errors and logging to Sentry
 * Use the errorFilter param to specify which errors that should be captured
 * Some code taken from https://github.com/getsentry/sentry-javascript/blob/master/packages/react/src/errorboundary.tsx
 * Requires React >= 16
 */

const INITIAL_STATE = {
  componentStack: null,
  error: null,
  eventId: null
};

export default class ErrorBoundary extends Component {
  state = INITIAL_STATE;

  componentDidCatch(error, { componentStack }) {
    const {
      boundaryId = false,
      errorFilter = () => {
        return true;
      },
      onError = () => {}
    } = this.props;

    withScope(scope => {
      if (boundaryId) {
        scope.setTag("location", boundaryId);
      }
      const eventId = captureException(error, {
        contexts: { react: { componentStack } }
      });
      const passed = errorFilter(error, componentStack);
      if (!passed) return;

      if (onError) {
        onError(error, componentStack, eventId);
      }

      // componentDidCatch is used over getDerivedStateFromError
      // so that componentStack is accessible through state.
      this.setState({ error, componentStack, eventId });
    });
  }

  componentWillUnmount() {
    const { error, componentStack, eventId } = this.state;
    const { onUnmount } = this.props;
    if (onUnmount) {
      onUnmount(error, componentStack, eventId);
    }
  }

  resetErrorBoundary = () => {
    const { onReset = () => {} } = this.props;
    const { error, componentStack, eventId } = this.state;
    if (onReset) {
      onReset(error, componentStack, eventId);
    }
    this.setState(INITIAL_STATE);
  };

  render() {
    const { fallback = null } = this.props;
    const { error, componentStack, eventId } = this.state;

    if (error) {
      if (isValidElement(fallback)) {
        return fallback;
      }
      if (typeof fallback === "function") {
        return fallback({
          error,
          componentStack,
          resetError: this.resetErrorBoundary,
          eventId
        });
      }

      // Fail gracefully if no fallback provided
      return null;
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  boundaryId: PropTypes.string,
  fallback: PropTypes.object,
  errorFilter: PropTypes.func,
  onError: PropTypes.func,
  onReset: PropTypes.func
};
