import { useUser } from "../functional-components/UserContext";
import { useMemo } from "react";
import { useSetting } from "./SettingsHooks";

export type SiteFeaturesOptions = {
  products: boolean;
  prices: boolean;
  shop: boolean;
  checkout: boolean;
  login: boolean;
  guides: boolean;
  sales_regions: boolean;
};

export class SiteFeatures {
  _features: Record<string, boolean> = {};

  constructor(features: Partial<SiteFeaturesOptions>) {
    this._features = features;
  }

  hasFeature(feature: string): boolean {
    return this._features[feature] || false;
  }

  /**
   * Whether a catalog of products should be available
   */
  products(): boolean {
    return this.hasFeature("products");
  }

  /**
   * Whether prices should be visible
   */
  prices(): boolean {
    return this.hasFeature("prices");
  }

  /**
   * Whether a shopping cart should be available
   */
  shop(): boolean {
    return this.hasFeature("shop");
  }

  /**
   * Whether checkout of a product cart should be possible
   */
  checkout(): boolean {
    return this.hasFeature("checkout");
  }

  /**
   * Whether it should be possible to register a user account and login
   */
  login(): boolean {
    return this.hasFeature("login");
  }

  /**
   * Whether guides are active
   */
  guides(): boolean {
    return this.hasFeature("guides");
  }

  /**
   * Whether we should display sales regions
   */
  salesRegions(): boolean {
    return this.hasFeature("sales_regions");
  }
}

export function useSiteFeatures(): SiteFeatures {
  const setting: string = useSetting("site_features", "");
  const user = useUser();

  return useMemo(() => {
    switch (setting) {
      case "cms":
        return new SiteFeatures({
          products: false,
          prices: false,
          shop: false,
          checkout: false,
          login: false,
          guides: false
        });
      case "catalog":
        return new SiteFeatures({
          products: true,
          prices: false,
          shop: false,
          checkout: false,
          login: false,
          guides: false,
          sales_regions: true
        });
      case "catalog-with-prices":
        return new SiteFeatures({
          products: true,
          prices: true,
          shop: false,
          checkout: false,
          login: false,
          guides: false,
          sales_regions: true
        });
      case "shop":
        return new SiteFeatures({
          products: true,
          prices: true,
          shop: user.allowShopping !== false,
          checkout: true,
          login: true,
          guides: !!user.admin
        });
      case "no-checkout":
        return new SiteFeatures({
          products: true,
          prices: true,
          shop: user.allowShopping !== false,
          checkout: false,
          login: true,
          guides: !!user.admin
        });
      default:
        return new SiteFeatures({
          products: false,
          prices: false,
          shop: false,
          checkout: false,
          login: false,
          guides: false
        });
    }
  }, [setting, user.admin, user.allowShopping]);
}
