import { useNavigationContext } from "../functional-components/NavigationContext";
import { getCurrentSiteDefaultLanguage } from "../utils/SiteUtils";
import { Language } from "../config/languages";

export function useLang(): Language {
  const { lang } = useNavigationContext();
  return lang || getCurrentSiteDefaultLanguage();
}

export function usePathLang(): string {
  const { pathLang } = useNavigationContext();
  return pathLang;
}
