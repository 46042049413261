//Reload after 5 h of inactivity to prevent chunk load errors
export default function inactiveReloading() {
  let inactiveTimestamp = 0;
  document.addEventListener("visibilitychange", function () {
    if (document.visibilityState !== "visible") {
      // tab is now inactive
      inactiveTimestamp = new Date().getTime();
    } else {
      // tab is active again
      if (
        inactiveTimestamp > 0 &&
        inactiveTimestamp + 3600 * 1000 < new Date().getTime() &&
        (navigator.onLine === undefined || navigator.onLine === true)
      ) {
        //Sentry.captureMessage("Expired tab reload", "debug");
        window.location.reload();
      }
    }
  });
}
