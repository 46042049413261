import { Site } from "./sites";

const _languages = [
  "zh",
  "en",
  "de",
  "da",
  "es",
  "fi",
  "fr",
  "hr",
  "it",
  "no",
  "sv",
  "en",
  "en-us"
] as const;
export type Language = typeof _languages[number];
export const languages: Language[] = [..._languages];

export const defaultLanguageLookup: Readonly<Record<Site, Language>> = {
  cn: "zh",
  com: "en",
  de: "de",
  dk: "da",
  es: "es",
  fi: "fi",
  fr: "fr",
  hr: "hr",
  it: "it",
  no: "no",
  se: "sv",
  uk: "en",
  us: "en-us"
};
