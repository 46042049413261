import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import {
  confirmPasswordReset,
  getAuth,
  onAuthStateChanged,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signOut,
  updatePassword,
  User as FirebaseUser,
  UserCredential
} from "firebase/auth";
import { useQuery } from "react-query";
import apiService from "../services/apiService";
import firebaseConfig from "../config/firebaseConfig";
import userService from "../services/userService";
import { useSiteFeatures } from "../hooks/useSiteFeatures";
import ErrorCodes from "../config/ErrorCodes";
import { ERROR } from "../components/UIMessages";
import { accountTypes } from "../components/form/fields/AccountTypeField";
import { setUser } from "@sentry/react";
import { UserDto } from "../types/User";

export type UserContextValue = {
  firebaseUser: FirebaseUser | null;
  userData: UserData | undefined;
  setUserData: (
    value:
      | ((prevState: UserData | undefined) => UserData)
      | UserData
      | undefined
  ) => void;
  updateUserData: (data: UserDto | undefined, admin?: boolean) => void;
  updatePassword: (user: FirebaseUser, newPassword: string) => Promise<void>;
  hasLoginFeature: boolean;
  signIn: (username: string, password: string) => Promise<UserCredential>;
  signInWithCustomToken: (token: string) => Promise<UserCredential>;
  signOut: () => Promise<void>;
  confirmPasswordReset: (code: string, newPassword: string) => Promise<void>;
  translationAdminModeOn: boolean;
  setTranslationAdminModeOn: (
    value: ((prevState: boolean) => boolean) | boolean
  ) => void;
};

export type UserData = {
  authenticated: boolean;
  b2b: boolean;
  isBusiness: boolean;
  userSecrets: string | null;
  anonymous: boolean;
  admin: boolean;
  user: UserDto | null;
  allowShopping?: boolean;
};

export const UserContext = createContext<UserContextValue | null>(null);

const defaultUserData = _getCompoundUserData(null, false);

export const UserContextProvider = ({ children }: PropsWithChildren) => {
  const features = useSiteFeatures();
  const hasLoginFeature = useMemo(() => features.login(), [features]);
  const auth = useRef(getAuth(firebaseConfig.getApp()));
  const [firebaseUser, setFirebaseUser] = useState<FirebaseUser | null>(
    auth.current.currentUser
  );
  const [userData, setUserData] = useState<UserData | undefined>(
    defaultUserData
  );
  const [translationAdminModeOn, setTranslationAdminModeOn] =
    useState<boolean>(false);

  async function _signIn(
    username: string,
    password: string
  ): Promise<UserCredential> {
    return signInWithEmailAndPassword(auth.current, username, password);
  }

  async function _signInWithCustomToken(
    token: string
  ): Promise<UserCredential> {
    return signInWithCustomToken(auth.current, token);
  }

  async function _signOut(): Promise<void> {
    await signOut(auth.current);
  }

  async function _confirmPasswordReset(
    code: string,
    newPassword: string
  ): Promise<void> {
    await confirmPasswordReset(auth.current, code, newPassword);
  }

  function _updateUserData(user?: UserDto, admin = false): void {
    setUser(_getCompoundUserData(user || null, admin));
  }

  function _updatePassword(
    user: FirebaseUser,
    newPassword: string
  ): Promise<void> {
    return updatePassword(user, newPassword);
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth.current, user => {
      console.debug("onAuthStateChanged", user);
      setFirebaseUser(user);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <UserContext.Provider
      value={{
        firebaseUser,
        userData,
        setUserData,
        updateUserData: _updateUserData,
        updatePassword: _updatePassword,
        hasLoginFeature,
        signIn: _signIn,
        signInWithCustomToken: _signInWithCustomToken,
        signOut: _signOut,
        confirmPasswordReset: _confirmPasswordReset,
        translationAdminModeOn,
        setTranslationAdminModeOn
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

function _getCompoundUserData(
  user: UserDto | null,
  isAdmin: boolean | undefined
): UserData {
  const ret = {
    user: user,
    userSecrets: user?.secrets || null,
    anonymous: !user,
    authenticated: !!user,
    admin: !!isAdmin,
    b2b: user?.properties?.b2b === true,
    allowShopping: !user || !!user?.properties?.allowShopping,
    isBusiness: user?.accountType === accountTypes.businessAccount
  };
  console.log("_getCompoundUserData", user, isAdmin, ret);
  return ret;
}

function _fetchUserData(
  firebaseUser: FirebaseUser,
  hasLoginFeature = false
): Promise<{ user: UserDto; isAdmin: boolean } | null> {
  return new Promise((resolve, reject) => {
    if (firebaseUser === null) {
      resolve(null);
    } else {
      apiService
        .getUser()
        .then(({ data: user }) => {
          firebaseUser
            .getIdTokenResult(false)
            .then(idTokenResult => {
              const isAdmin = idTokenResult.claims.role === "admin";
              if (hasLoginFeature || isAdmin) {
                resolve({ user, isAdmin });
              } else {
                resolve(null);
              }
            })
            .catch(error => {
              console.error("getIdTokenResult error", error);
              resolve(null);
            });
        })
        .catch(error => {
          console.error("getUser error", error);

          let errorCode = ErrorCodes.ERROR_GET_USER;
          if (error?.response?.status === 400) {
            errorCode =
              error.response?.data?.message || ErrorCodes.ERROR_GET_USER;
          }
          reject({
            errorCode,
            error: true,
            type: ERROR
          });
        });
    }
  });
}

/** Try to get current user, but returns an authenticated user if current user hasn't been cached yet. */
export function useUser(): UserData {
  const userContext = useContext(UserContext);
  //console.debug("useUser:", userContext?.userData || defaultUserData);
  return userContext?.userData || defaultUserData; //fallback when called before the context has been initialized
}

/** Get current user. Will suspend until user data is loaded */
export function useCurrentUser(): UserData {
  const userContext = useContext(UserContext);
  const firebaseUser = userContext?.firebaseUser;
  const hasLoginFeature = !!userContext?.hasLoginFeature;

  const { data: retUser } = useQuery(
    ["getUserData", firebaseUser?.uid || "", hasLoginFeature],
    async (): Promise<UserData> => {
      console.debug("useQuery: getUserData", firebaseUser);
      if (firebaseUser) {
        try {
          const userData = await _fetchUserData(firebaseUser, hasLoginFeature);
          console.debug("_fetchUserData:", userData);
          if (userData !== null && (hasLoginFeature || userData.isAdmin)) {
            return _getCompoundUserData(userData.user, userData.isAdmin);
          }
          // eslint-disable-next-line
        } catch (e: any) {
          console.warn("error", e);
          e.errorCode = ErrorCodes.ERROR_GET_USER;
          if (e?.response?.status === 400) {
            e.errorCode =
              e.response?.data?.message || ErrorCodes.ERROR_GET_USER;
            userContext.signOut();
          }
        }
      }
      return defaultUserData;
    },
    {
      retry: false,
      //useErrorBoundary: false,
      //staleTime: 3000,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchInterval: 0
    }
  );

  useEffect(() => {
    console.debug("useCurrentUser:", retUser);
    //Store the secrets here to be accessible from outside React components and hooks.
    userService.setSecrets(retUser?.userSecrets);
    userContext?.setUserData(retUser);
  }, [retUser, userContext]);

  if (retUser === undefined) {
    throw new Error("This shouldn't be possible!");
  }
  return retUser;
}

export function useUserCountries() {
  const { user, b2b } = useUser();
  const result: string[] = [];
  if (user) {
    for (const address of [user?.billingAddress, user?.shippingAddress]) {
      const countryCode = address?.countryCode;
      if (typeof countryCode === "string") {
        result.push(countryCode);
      }
    }
    if (b2b) {
      result.push("NO", "DK", "FI", "IS", "UK", "DE");
    }
  }
  return result;
}
