import uniq from "lodash/uniq";
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useRef,
  useState
} from "react";
import useLocalStorage from "use-local-storage";
import DeferredPromise from "../../utils/DeferredPromise";
import { ERROR, INFO } from ".";
import { UiMessage } from "../../types/UiMessage";

type UiMessageContextType = {
  getMessages: () => UiMessage[];
  getDismissedBannerMessages: () => string[];
  displayMessage: (message: UiMessage) => void;
  dismissMessage: (key: string) => void;
  emptyMessages: () => void;
  dismissBannerMessage: (bannerMessageHash: string) => void;
  resetBannerMessages: () => void;
  displayConfirmDialog: (message: string) => DeferredPromise;
  getActiveConfirmDialog: () => string | undefined;
  confirmActiveDialog: () => void;
  dismissActiveDialog: () => void;
};

export const UiMessageContext = createContext<UiMessageContextType | null>(
  null
);

export function UiMessageContextProvider({
  children
}: PropsWithChildren<unknown>): JSX.Element {
  const [messages, setMessages] = useState<UiMessage[]>([]);
  const [confirmDialogMsg, setConfirmDialogMsg] = useState<string>();
  const confirmDialogPromise = useRef<DeferredPromise>();
  const [dismissedBannerMessages, setDismissedBannerMessages] = useLocalStorage<
    string[]
  >("dismissedMessages", []);

  //console.debug("UiMessageContextProvider render", messages);

  const dismissMessage = useCallback(
    (key: string) => {
      setMessages(state => state.filter(msg => msg.identifier !== key));
    },
    [setMessages]
  );

  const emptyMessages = useCallback(() => {
    setMessages([]);
  }, [setMessages]);

  const dismissBannerMessage = useCallback(
    (hash: string) => {
      if (!dismissedBannerMessages) {
        setDismissedBannerMessages([hash]);
      } else {
        setDismissedBannerMessages(state => uniq([...(state || []), hash]));
      }
    },
    [dismissedBannerMessages, setDismissedBannerMessages]
  );

  const resetBannerMessages = useCallback(() => {
    setDismissedBannerMessages([]);
  }, [setDismissedBannerMessages]);

  /**
   * Displays a message to the user
   * @param text the message
   * @param type type of message (INFO|SUCCESS|WARN|ERROR)
   * @param persist user must confirm - default for ERROR
   * @param banner display as banner above the top navigation
   */
  const displayMessage = useCallback((message: UiMessage) => {
    message = {
      persist: !!message.banner || message.type === ERROR,
      type: INFO,
      code: "",
      ...message,
      identifier:
        message.identifier ||
        (message.banner ? message.text : Math.random().toString())
    };

    setMessages(state => {
      const index = state.findIndex(msg =>
        msg.identifier
          ? msg.identifier === message.identifier
          : msg.text === message.text
      );
      if (index >= 0) {
        state[index] = message;
        return [...state];
      } else {
        return [...state, message];
      }
    });
  }, []);

  const getMessages = useCallback(() => {
    return messages;
  }, [messages]);

  const getDismissedBannerMessages = useCallback(() => {
    if (!dismissedBannerMessages) return [];
    return dismissedBannerMessages;
  }, [dismissedBannerMessages]);

  /**
   * Usage example:
   setLoading(true);
   UiContext.displayConfirmDialog("Are you sure?")
   .then(() => {
   //do stuff
   })
   .catch(() => {})
   .finally(() => {
   setLoading(false);
   });
   */
  const displayConfirmDialog = useCallback((message: string) => {
    setConfirmDialogMsg(message);

    const pr = new DeferredPromise();
    confirmDialogPromise.current = pr;
    return pr;
  }, []);

  const getActiveConfirmDialog = useCallback(() => {
    return confirmDialogMsg;
  }, [confirmDialogMsg]);

  const confirmActiveDialog = useCallback(() => {
    confirmDialogPromise.current?.resolve("CONFIRMED");
    setConfirmDialogMsg(undefined);
    confirmDialogPromise.current = undefined;
  }, []);

  const dismissActiveDialog = useCallback(() => {
    confirmDialogPromise.current?.reject("DISMISSED");
    setConfirmDialogMsg(undefined);
    confirmDialogPromise.current = undefined;
  }, []);

  return (
    <UiMessageContext.Provider
      value={{
        getMessages,
        getDismissedBannerMessages,
        displayMessage,
        dismissMessage,
        emptyMessages,
        dismissBannerMessage,
        resetBannerMessages,
        displayConfirmDialog,
        getActiveConfirmDialog,
        confirmActiveDialog,
        dismissActiveDialog
      }}
    >
      {children}
    </UiMessageContext.Provider>
  );
}

export const useUiMessageContext = (): UiMessageContextType => {
  const context = useContext(UiMessageContext);
  if (!context)
    throw new Error(
      "useUiMessageContext must be used within a UiMessageContextProvider"
    );
  return context;
};
