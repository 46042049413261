import BigNumber from "bignumber.js";

export function multiply(a: BigNumber.Value, b: BigNumber.Value) {
  const A = new BigNumber(a);
  const B = new BigNumber(b);
  const product = A.multipliedBy(B);
  return product.toNumber();
}

export function divide(a: BigNumber.Value, b: BigNumber.Value) {
  const A = new BigNumber(a);
  const B = new BigNumber(b);
  const fraction = A.dividedBy(B);
  return fraction.toNumber();
}

//From https://stackoverflow.com/questions/59678901/using-parsefloat-in-different-locales
export function fromLocaleString(s: string) {
  // Get the thousands and decimal separator characters used in the locale.
  const [, thousandsSeparator, , , , decimalSeparator] =
    (1111.1).toLocaleString();
  // Remove thousands separators, and put a point where the decimal separator occurs
  return Array.from(s, c =>
    c === thousandsSeparator ? "" : c === decimalSeparator ? "." : c
  ).join("");
}

//From https://stackoverflow.com/questions/175739/built-in-way-in-javascript-to-check-if-a-string-is-a-valid-number
export function isLocaleNumeric(s?: string) {
  if (typeof s != "string") return false; // we only process strings!
  const str = fromLocaleString(s);
  // could also coerce to string: str = ""+str
  return !isNaN(Number(str)) && !isNaN(parseFloat(str));
}

export function localeParseFloat(s: string) {
  return parseFloat(fromLocaleString(s));
}

export function formatDistance(meters: number) {
  return meters < 950
    ? Math.round(meters) + " m"
    : Math.round(meters / 1000) + " km";
}
