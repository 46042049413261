import { lazy, Suspense } from "react";
import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";
import LoadingAnim from "./components/LoadingAnim";
import ErrorBoundary from "./functional-components/ErrorBoundary";
import ErrorDisplay from "./components/ErrorDisplay";
import {
  QueryClient,
  QueryClientProvider,
  QueryErrorResetBoundary
} from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import inactiveReloading from "./utils/inactiveReloading";
import { getBuildVersion } from "./utils/VersionUtils";

const Layout = lazy(() => import("./layout"));

Sentry.init({
  dsn: "https://313864bde5794838817e003709b3a78d@o449769.ingest.sentry.io/5433315",
  integrations: [
    Sentry.browserSessionIntegration(),
    Sentry.reactRouterV5BrowserTracingIntegration({
      history
    }),
    Sentry.captureConsoleIntegration({
      handled: false,
      levels: ["error"]
    })
  ],
  attachStacktrace: true,
  tracesSampleRate: 0.05,
  environment: window.location.hostname,
  release: import.meta.env.VITE_VERSION || undefined,
  ignoreErrors: [
    //Note: When using strings, they’ll partially match the messages
    "ResizeObserver loop limit exceeded",
    "Object Not Found Matching Id" //See https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/17
  ],
  denyUrls: [
    /:\/\/localhost/,
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i
  ],
  beforeSend(event, hint) {
    //See: https://github.com/getsentry/sentry-javascript/issues/2514
    if (hint.originalException === "Timeout") return null;
    return event;
  }
});

Sentry.setUser({ ip_address: "{{auto}}" });
getBuildVersion().then(buildVersion => {
  Sentry.setTag("bjelin_web_version", buildVersion);
});

inactiveReloading();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
      staleTime: Infinity,
      refetchInterval: 1000 * 3600 * 3
    }
  }
});

const isPrint = window.location.href.includes("/print/");
const container = document.getElementById("root");
const root = createRoot(container!, {
  onRecoverableError: Sentry.reactErrorHandler()
});
root.render(
  <QueryClientProvider client={queryClient}>
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary
          boundaryId="root"
          onReset={reset}
          fallback={<ErrorDisplay />}
        >
          <Suspense fallback={isPrint ? null : <LoadingAnim log={"root"} />}>
            <Layout />
          </Suspense>
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
);
