import { useBjelinTranslations } from "../../hooks/TranslationHooks";
import BjelinButton from "../BjelinButton";
import PrerenderStatus from "../PrerenderStatus";

const ErrorDisplay = () => {
  const t = useBjelinTranslations();
  return (
    <>
      <PrerenderStatus code={500} />
      <div
        className="error-display-general"
        style={{
          position: "absolute",
          zIndex: 1000,
          top: 0,
          bottom: "30vh",
          left: 0,
          right: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "#fff",
          textShadow: "0 0 20px rgba(0,0,0,0.8)"
        }}
      >
        <div
          style={{
            textAlign: "center"
          }}
        >
          <h2>{t("error.general", "An unexpected error occurred.")}</h2>
          <p>
            {t(
              "error.general.descr",
              "We are sorry for the interruption. Try reloading the page. If that doesn't help, please contact our customer service."
            )}
            <br />
            <a href={"mailto:" + t("support.email", "support@bjelin.com")}>
              {t("support.email", "support@bjelin.com")}
            </a>
            <br />
            <a
              href={
                "tel:" +
                t("support.phone", "+46-771-101 099").replace(/[- ]+/g, "")
              }
            >
              {t("support.phone", "+46-771-101 099")}
            </a>
          </p>
          <button
            className="error-reload-button"
            style={{
              padding: "0.85em 1em",
              background: "#ffffff",
              border: "2px solid #ffffff",
              borderRadius: "6px"
            }}
            onClick={() => {
              window.location.reload();
            }}
          >
            {t("action.reload", "Reload")}
          </button>
        </div>
      </div>
    </>
  );
};

export default ErrorDisplay;

export const ErrorDisplaySmall = () => {
  const t = useBjelinTranslations();
  return (
    <div
      className="error-display-small"
      style={{
        position: "absolute",
        zIndex: 1000,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#444",
        background: "#fff"
      }}
    >
      <div
        style={{
          textAlign: "center"
        }}
      >
        <p>{t("error.general.short")}</p>
        <BjelinButton
          color="bjelin-green"
          block={false}
          lg={false}
          className={"mb-3"}
          onClick={() => {
            window.location.reload();
          }}
        >
          {t("action.reload")}
        </BjelinButton>
      </div>
    </div>
  );
};
