import Cookies from "js-cookie";
import { defaultLanguageLookup, Language } from "../config/languages";
import { Site, sites } from "../config/sites";

function siteFromCookies(): Site | null {
  const site = Cookies.get("site") as Site;
  return site && sites.indexOf(site) > -1 ? site : null;
}

export function siteFromHostname(hostname: string): Site {
  for (const site of sites) {
    // ie. se.bjelin.com
    if (hostname.startsWith(site + ".")) return site;
    // ie. dev-se.bjelin.io
    if (hostname.indexOf("-" + site) > -1) return site;
    // ie. bjelin.se
    if (hostname.endsWith("." + site)) return site;
  }
  return "se";
}

export function siteFromLang(lang: Language): Site {
  switch (lang) {
    case "sv":
      return "se";
    case "en":
      return "com";
    case "en-us":
      return "us";
    case "da":
      return "dk";
    case "fi":
      return "fi";
    case "no":
      return "no";
    case "hr":
      return "hr";
    case "fr":
      return "fr";
    case "es":
      return "es";
    case "de":
      return "de";
    case "zh":
      return "cn";
    case "it":
      return "it";
    default:
      return "com";
  }
}

export function siteType(
  hostname?: string
): "local" | "dev" | "staging" | "prod" {
  if (hostname === undefined) hostname = window.location.hostname;
  if (hostname === "localhost") return "local";
  if (hostname.match(/^dev[.-]/) || hostname.indexOf("-dev.") > -1)
    return "dev";
  if (hostname.match(/^staging[.-]/) || hostname.indexOf("-staging.") > -1)
    return "staging";
  if (hostname.match(/^prod[.-]/) || hostname.indexOf("-prod.") > -1)
    return "prod";
  if (hostname.match(/\.herokuapp\.com$/)) {
    return "dev";
  }
  return "prod";
}

export function siteUrl(
  site: string,
  lang?: string,
  hostname?: string,
  noTrailingSlash?: boolean
) {
  lang = lang ? "/" + lang : "";
  switch (siteType(hostname)) {
    case "local":
    case "dev":
      return (
        `https://dev-${site}.bjelin.io${lang}` + (noTrailingSlash ? "" : "/")
      );
    case "staging":
      return (
        `https://staging-${site}.bjelin.io${lang}` +
        (noTrailingSlash ? "" : "/")
      );
    default:
    case "prod":
      if (site === "com" || site === "se") {
        return (
          `https://www.bjelin.${site}${lang}` + (noTrailingSlash ? "" : "/")
        );
      } else {
        return (
          `https://${site}.bjelin.com${lang}` + (noTrailingSlash ? "" : "/")
        );
      }
  }
}

export function fixSiteLanguage(site: Site, lang: Language): Language {
  return site === "us" && lang === "en-us" ? "en" : lang;
}

export function getCurrentSite(): Site {
  const printSite = window.location.href.match(
    new RegExp(`/print/(${sites.join("|")})/[a-z]{2}/`)
  )?.[1] as Site;

  return (
    printSite ||
    siteFromCookies() ||
    (import.meta.env.VITE_APP_SITE as Site) ||
    siteFromHostname(window.location.hostname)
  );
}

export function getCurrentSiteDefaultLanguage(): Language {
  return defaultLanguageLookup[getCurrentSite()];
}

export function htmlLangFor(site: Site, lang: Language): string {
  switch (site) {
    case "uk":
      return `${lang}-GB`;
    case "us":
      return `${lang}-US`;
    default:
      return lang;
  }
}
