import { FormGroup, Label } from "reactstrap";
import PropTypes from "prop-types";
import { useBjelinTranslations } from "../../../hooks/TranslationHooks";

export const accountTypes = {
  privateAccount: "private",
  businessAccount: "business",
  b2b: "b2b"
};

const AccountTypeField = ({
  accountType,
  callback,
  readOnly = false,
  disableBusiness = false
}) => {
  const t = useBjelinTranslations();
  if (readOnly) {
    return (
      <FormGroup>
        <Label>{t("account.type")}</Label>
        {t("account.type." + accountType)}
      </FormGroup>
    );
  }
  const onChange = e => callback(e.target.value);
  return (
    <FormGroup>
      <Label>{t("account.type")}</Label>
      <Label check className={"d-inline-block me-4"}>
        <input
          type="radio"
          name={"accountType"}
          className={"input-" + accountTypes.privateAccount}
          value={accountTypes.privateAccount}
          checked={accountType === accountTypes.privateAccount}
          onChange={onChange}
        />
        {" " + t("account.type.private")}
      </Label>
      <Label check className={"d-inline-block"}>
        <input
          type="radio"
          name={"accountType"}
          className={"input-" + accountTypes.businessAccount}
          value={accountTypes.businessAccount}
          checked={accountType === accountTypes.businessAccount}
          disabled={disableBusiness}
          onChange={onChange}
        />
        {" " + t("account.type.business")}
        {disableBusiness && " – " + t("account.type.business.disabled")}
      </Label>
    </FormGroup>
  );
};

AccountTypeField.propTypes = {
  accountType: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired
};

export default AccountTypeField;
