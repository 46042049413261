import { TFunction } from "i18next";
import { DateTime } from "luxon";

export function nextIsoWeekdayNotTomorrow(isoWeekday: number): string {
  let today = DateTime.now();
  const currentWeekday = today.weekday;
  const dayDiff = (isoWeekday + 7 - currentWeekday) % 7 || 7;
  if (dayDiff < 2) {
    today = today.plus({ days: 7 });
  }
  return today.plus({ days: dayDiff }).toISODate();
}

export function translateComingDate(
  dateString: string | undefined,
  tp: TFunction<"products", undefined>
): { translation: string; diff: number } | Record<string, never> {
  if (!dateString) return {};
  const today = DateTime.now().startOf("day");
  const targetDate = DateTime.fromISO(dateString).startOf("day");
  const dayDiff = targetDate.diff(today, "days").days;
  let translation;
  if (dayDiff < 0) {
    translation = "";
  } else if (dayDiff === 0) {
    translation = tp("day.today");
  } else if (dayDiff === 1) {
    translation = tp("day.tomorrow");
  } else if (dayDiff <= 7) {
    translation = tp(
      "day.on." + targetDate.setLocale("en").toFormat("ccc").toLowerCase()
    );
  } else if (dayDiff < 50) {
    translation = tp("in.weeks", { weeks: Math.ceil(dayDiff / 7) });
  } else {
    translation = tp("in.months", { months: Math.ceil(dayDiff / 30) });
  }
  return { translation, diff: dayDiff };
}
