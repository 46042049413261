import "./style.scss";
import { useEffect, useState } from "react";
import { AnimatePresence, motion, useTime, useTransform } from "motion/react";
import classNames from "classnames";
import Icon from "../Icon";

type Progress = {
  progress: number;
  duration?: number;
  className?: string;
  type?: "circular";
  onDone?: () => number;
};

type CircularProgress = {
  progress: number;
  duration?: number;
  className?: string;
  onDone?: () => void;
};

export const CircularProgress: React.FC<CircularProgress> = ({
  progress,
  duration = 0.5,
  className = "",
  onDone = () => {}
}) => {
  const size = 100;
  const strokeWidth = 50;

  const [done, setDone] = useState(duration === 0 && progress === 100);
  const radius = (size - strokeWidth) / 2;
  const circumference = radius * 2 * Math.PI;
  const offset = circumference - (progress / 100) * circumference;

  const time = useTime();
  const strokeDashoffset = useTransform(
    time,
    [0, duration * 1000],
    [circumference, offset]
  );

  strokeDashoffset.on("change", v => {
    if (offset === 0 && v === offset) {
      setDone(true);
    }
  });

  useEffect(() => {
    if (done) {
      onDone();
    }
  }, [done, onDone]);

  return (
    <motion.div
      className={classNames("circular-progress", {
        [className]: className,
        done: done
      })}
      variants={{
        done: {
          scale: 1.1,
          transition: {
            duration: 0.25,
            repeat: Infinity,
            repeatType: "reverse"
          }
        }
      }}
      animate={done ? "done" : "initial"}
    >
      <svg viewBox={`0 0 ${size} ${size}`}>
        <motion.circle
          className={"circular-progress__progress"}
          strokeWidth={strokeWidth}
          fill="none"
          r={radius}
          cx={"50%"}
          cy={"50%"}
          style={{
            strokeDasharray: circumference,
            strokeDashoffset
          }}
        />
      </svg>
      <div className="circular-progress__content">
        <AnimatePresence>
          {done && (
            <motion.div
              className={"circular-progress__icon"}
              initial={{
                scale: 0,
                rotate: "-45deg"
              }}
              animate={{
                scale: 1,
                rotate: 0
              }}
              exit={{
                scale: 0
              }}
            >
              <Icon name={"check"} />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </motion.div>
  );
};

const Progress: React.FC<Progress> = ({ type, ...props }) => {
  if (type === "circular") {
    return <CircularProgress {...props} />;
  } else {
    console.error("Progress type not supported");
    return <div>Progress type not supported</div>;
  }
};

export default Progress;
