export const sites = [
  "se",
  "dk",
  "fi",
  "no",
  "hr",
  "fr",
  "it",
  "es",
  "de",
  "us",
  "uk",
  "cn",
  "com"
] as const;
export type Site = typeof sites[number];
