export function getSpecsIndexed(specifications) {
  if (!Array.isArray(specifications)) return {};
  const specsIndexed = specifications.reduce((map, [field, rawValue]) => {
    const match = rawValue.match(/^([0-9,.± ]+)\s([^0-9,.±]+)$/);
    if (match) {
      const [, numValue, unit] = match;
      map[field] = { value: numValue, unit };
    } else {
      map[field] = { value: rawValue };
    }
    return map;
  }, {});
  //console.log("getSpecsIndexed", specsIndexed);
  return specsIndexed;
}

export function getGroupedSpecifications(
  specifications,
  specsIndexed,
  settings
) {
  if (!Array.isArray(specifications)) return {};
  const defs = settings.specifications;
  const hiddenSpecs = Array.isArray(settings.hidden_specifications)
    ? settings.hidden_specifications
    : [];
  const specsGrouped = specifications.reduce(
    (map, [field, rawValue]) => {
      const { value, unit } = specsIndexed[field];
      const hidden = hiddenSpecs.includes(field);
      if (defs[field]) {
        const {
          description,
          group,
          icons,
          key,
          note,
          unit: defaultUnit,
          value_descriptions
        } = defs[field];
        const finalGroup = group || "General";
        if (map[finalGroup] === undefined) map[finalGroup] = [];
        map[finalGroup].push({
          field,
          value,
          unit,
          note,
          group: finalGroup,
          hidden
        });
      } else {
        //console.debug("Missing specification definition for " + field);
        const finalGroup = "Misc";
        if (map[finalGroup] === undefined) map[finalGroup] = [];
        map[finalGroup].push({
          field,
          value,
          unit,
          note: null,
          group: finalGroup,
          hidden
        });
      }
      return map;
    },
    //This will make the groups sorted in the key order:
    { General: [], Dimensions: [], Package: [], Safety: [], Misc: [] }
  );

  //console.log("specsGrouped", specsGrouped);
  return specsGrouped;
}

export function enhanceProductSpecifications(product, settings) {
  if (product) {
    product.specsIndexed = getSpecsIndexed(product.specifications);
    product.specsGrouped = getGroupedSpecifications(
      product.specifications,
      product.specsIndexed,
      settings
    );
  }
  return product;
}
