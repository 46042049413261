import { Suspense } from "react";
import { useQuery } from "react-query";
import {
  convertHtmlToReact,
  convertNodeToReactElement
} from "@hedgedoc/html-to-react";
import classNames from "classnames";
import ErrorBoundary from "../../functional-components/ErrorBoundary";
import axios from "axios";
import { cdnBaseUrl } from "../../config/urls";
import { AnimatePresence, motion } from "motion/react";

const iconTypes = {
  far: "regular",
  fas: "solid",
  fal: "light",
  fab: "brands"
};

const cdnClient = axios.create({
  baseURL: cdnBaseUrl,
  validateStatus: false
});

const IconPlaceholder = props => (
  <svg
    focusable="false"
    className={classNames(
      "svg-inline--fa",
      "fa-w-16",
      props.size ? "fa-" + props.size : "",
      props.missing ? "svg-missing" : "svg-placeholder",
      props.className
    )}
    role="img"
    aria-busy={props.isSuspense ? "true" : "false"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 496 512"
  ></svg>
);

function transformHTML(node, index, props = {}) {
  if (node.type === "tag" && node.name === "svg") {
    const { viewbox, ...rest } = node.attribs;
    return (
      <svg
        {...rest}
        focusable="false"
        className={classNames(
          "icon",
          "svg-inline--fa",
          "fa-" + props.name,
          props.size ? "fa-" + props.size : "",
          props.className
        )}
        role="img"
        aria-busy="false"
        xmlns="http://www.w3.org/2000/svg"
        viewBox={viewbox}
        fill="currentColor"
        key={index}
      >
        {node.children.map(transformHTML)}
      </svg>
    );
  }
  return convertNodeToReactElement(node, index, transformHTML);
}

const DynamicLoadedIcon = ({
  name = "",
  type = "far",
  size = "1x",
  className,
  ...props
}) => {
  const { data: svg } = useQuery(
    ["icon", name, type, size, className],
    async () => {
      return new Promise((resolve, _reject) => {
        cdnClient
          .get(`/svgs/${iconTypes[type]}/${name}.svg`)
          .then(res => {
            if (res.status === 200) {
              return res.data;
            } else if (res.status === 404) {
              console.error("Icon missing", name);
              resolve(false);
            } else {
              console.error("Icon error", name, res.status);
              resolve(false);
            }
          })
          .then(content => {
            if (!content) {
              resolve(false);
            } else if (content.substring(0, 4) === "<svg") {
              const elm = convertHtmlToReact(content, {
                transform: (node, index) =>
                  transformHTML(node, index, { size, type, className, name })
              });
              resolve(elm);
            } else {
              console.error("Icon parse error", name);
              resolve(false);
            }
          });
      });
    },
    { useErrorBoundary: true, throwOnError: false, refetchInterval: false }
  );
  return svg ? (
    svg
  ) : (
    <IconPlaceholder size={size} className={className} missing {...props} />
  );
};

const Icon = props => {
  return (
    <Suspense fallback={<IconPlaceholder isSuspense {...props} />}>
      <AnimatePresence mode={"wait"} initial={false}>
        <motion.span
          key={props.name}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.25 } }}
          exit={{ opacity: 0, transition: { duration: 0.25 } }}
          className={classNames("icon-wrapper", props.outerClassName)}
        >
          <ErrorBoundary
            boundaryId={"icon"}
            fallback={<IconPlaceholder {...props} />}
          >
            <DynamicLoadedIcon {...props} />
          </ErrorBoundary>
        </motion.span>
      </AnimatePresence>
    </Suspense>
  );
};

export default Icon;
