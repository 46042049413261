import { useQuery } from "react-query";
import apiService from "../services/apiService";
import { useLang } from "./LangHooks";
import { Settings } from "../types/Settings";
import { Guide } from "../types/Guide";
import { getCurrentSite } from "../utils/SiteUtils";
import { ProductType } from "../types/ProductType";
import { Person } from "../types/News";
import { Language } from "../config/languages";
import { Site } from "../config/sites";

/**
 * Get settings that aren't language dependent
 * @deprecated should use useLocSettings directly instead
 */
export function useSettings(): Settings {
  return useLocSettings();
}

/**
 * Get settings for a certain site/lang combination
 */
export function useSettingsForSite(site: Site, lang: Language): Settings {
  const { data: settings } = useQuery(
    ["settingsForSite", site, lang],
    async () => apiService.loadSettingsForSite(site, lang)
  );
  return settings;
}

/**
 * Get a value from settings that isn't language dependent
 */
export function useSetting<T>(
  keyPath: string | (string | number)[],
  defaultValue: T
): T {
  const settings = useSettings();
  if (!Array.isArray(keyPath)) {
    keyPath = [keyPath];
  }
  // eslint-disable-next-line
  let setting: any = settings;
  for (const key of keyPath) {
    setting = setting?.[key];
  }
  return setting !== undefined ? setting : defaultValue;
}

export function useDefaultLanguage(): string {
  const { default_language } = useSettings();
  return default_language;
}

export function usePathDefaultLanguage(): Language {
  const { default_language } = useSettings();
  return getCurrentSite() === "us" ? "en" : (default_language as Language);
}

export function usePathLanguages(): Language[] {
  const { languages } = useSettings();
  return getCurrentSite() === "us" ? ["en"] : (languages as Language[]);
}

export function useDefaultShippingCountry(): string {
  return useSetting(["shipping_countries", 0], "");
}

/**
 * Get settings that are language dependent
 */
export function useLocSettings(): Settings {
  const lang = useLang();
  const { data: settings } = useQuery(["loc-settings", lang], async () =>
    apiService.loadSettings(lang)
  );
  return settings;
}

/**
 * Get a value from settings that is language dependent
 */
export function useLocSetting<T>(
  keyPath: string | (string | number)[],
  defaultValue?: T
): T {
  const settings = useLocSettings();
  if (!Array.isArray(keyPath)) {
    keyPath = [keyPath];
  }
  // eslint-disable-next-line
  let setting: any = settings;
  for (const key of keyPath) {
    setting = setting?.[key];
  }
  return setting !== undefined ? setting : defaultValue;
}

export function useProductTypes(): ProductType[] {
  return useLocSetting<Array<ProductType>>("product_types");
}

export function useProductTypeById(id?: string): ProductType | undefined {
  const entries = useProductTypes();
  return entries.find(e => e._id === id);
}

export function useProductTypeByPath(
  path: string,
  guides: Guide[]
): (ProductType & { guides: Guide[] }) | undefined {
  const entries = useProductTypes();
  const entry = entries.find(e => e.path === path);
  return entry
    ? {
        ...entry,
        guides: guides.filter(g => g.product_type?._id === entry._id)
      }
    : undefined;
}

export function usePeople(): Person[] {
  const lang = useLang();
  const { data: people } = useQuery(
    ["people", lang],
    async (): Promise<Person[]> => {
      return (await apiService.loadPeople(lang)) as Person[];
    },

    { refetchInterval: 1000 * 60 * 15 }
  );
  return people || [];
}

export function getPersonByCockpitId(people: Person[], id:string) {
  if (!id) return false;
  const f = people.filter(person => person._id === id);
  return f.length ? f.pop() : false;
}

export function usePersonWithCockpitId(id: string): Person | null {
  const people = usePeople();
  return getPersonByCockpitId(people, id) || null;
}