import Helmet from "react-helmet";
import { useEffect } from "react";

const PrerenderStatus = ({ code }: { code: number }) => {
  useEffect(() => {
    window.prerenderReady = true;
  }, []);
  return (
    <Helmet>
      <meta name="prerender-status-code" content={code.toString()} />
    </Helmet>
  );
};

export default PrerenderStatus;
