import { AnimatePresence, motion } from "motion/react";
import "./style.scss";
import Icon from "../Icon";
import KeyboardEventHandler from "@infinium/react-keyboard-event-handler";
import { useBjelinTranslations } from "../../hooks/TranslationHooks";

const ConfirmDialog = ({ message, confirm = () => {}, dismiss = () => {} }) => {
  const t = useBjelinTranslations();
  return (
    <AnimatePresence>
      <motion.div
        id="overlay-cover"
        key="overlay-cover"
        style={{
          position: "fixed",
          zIndex: 3000,
          top: "0",
          left: "0",
          right: "0",
          bottom: "0",
          background: "rgba(255,255,255,0.4)",
          willChange: "opacity"
        }}
        initial={{ opacity: 0, pointerEvents: "all" }}
        animate={{
          opacity: 1,
          pointerEvents: "all"
        }}
        transition={{ type: "spring", damping: 40, stiffness: 400 }}
        exit={{ opacity: 0, pointerEvents: "none" }}
      />
      <motion.div
        key={message}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ type: "spring", damping: 40, stiffness: 400 }}
        className={"ConfirmDialog"}
      >
        <Icon className="msg-icon" name="triangle-exclamation" size="lg" />
        <div className="msg-content">{message}</div>
        <div
          className="dismiss-btn"
          onClick={() => {
            dismiss();
          }}
        >
          {t("cancel")}
        </div>
        <div
          className="confirm-btn"
          onClick={() => {
            confirm();
          }}
        >
          {t("ok")}
        </div>
        <KeyboardEventHandler
          handleKeys={["esc", "enter"]}
          handleFocusableElements
          onKeyEvent={(key, e) => {
            switch (key) {
              case "enter":
                confirm();
                e.preventDefault();
                break;
              case "esc":
                dismiss();
                e.preventDefault();
                break;
              default:
            }
          }}
        />
      </motion.div>
    </AnimatePresence>
  );
};

export default ConfirmDialog;
