import { getSlug } from "../utils/CmsUtils";
import { Language } from "./languages";
import { ClaimType } from "../types/Claims";

export const homeUrl = "/";
export const loginUrl = "/login";
export const loginUrlWithRedirect = "/login?redirect=";
export const resetPasswordUrl = "/login/reset-password";
export const accountPageUrl = "/account";
export const accountAddressesPageUrl = "/account/addresses";
export const accountEditPageUrl = "/account/edit";
export const accountFaqPageUrl = "/account/faq";
export const accountOrdersPageUrl = "/account/orders";
export const adminPagesUrl = "/admin";
export const adminAdminsPageUrl = "/admin/admins";
export const adminOrdersPage = "/admin/orders";

export function adminOrdersOrder(docNum: string) {
  return `/admin/orders/order/${docNum}`;
}

export const shopUrl = "/shop";
export const checkoutUrl = "/shop/checkout";
export const b2bCheckoutUrl = "/shop/b2b-order";
export const postPurchaseUrl = "/shop/checkout/thanks";
export const orderBaseUrl = "/order";
export const articleRedirectBaseUrl = "/article";
export const storiesBaseUrl = "/stories";
export const newsBaseUrl = "/news";
export const supportBaseUrl = "/support";
export const claimsLandingUrl = "/claims";
export const claimsTypesListUrl = (wholesale = false) => {
  return "/claims/list" + (wholesale ? "?wholesale" : "");
};

export function claimsTypePageUrl(claimType: ClaimType, wholesale = false) {
  return (
    "/claims/type/" +
    claimType.id +
    "-" +
    getSlug(claimType.title) +
    (wholesale ? "?wholesale" : "")
  );
}

export const claimsTypePageUrlPattern = "/claims/type/:claim_type-*";

export function claimsTypeFormPageUrl(claimType: ClaimType) {
  return claimsTypePageUrl(claimType) + "/form";
}

export const claimsTypeFormPageUrlPattern = "/claims/type/:claim_type-*/form";

export function claimsWholesaleFormUrl(claimTypeId?: string) {
  return "/claims/wholesale/form" + (claimTypeId ? "?id=" + claimTypeId : "");
}

export const claimsThanksUrl = "/claims/thanks";
export const cdnBaseUrl = "https://cdn.bjelin.se";
export const docsBaseUrl = `${cdnBaseUrl}/docs/`;
export const guidesBaseUrl = supportBaseUrl + "/guides";

export function guidesGuideUrl(guide: string) {
  return `${guidesBaseUrl}/${guide}`;
}

export function guidesCategoryUrl(categoryPath: string) {
  return `${guidesBaseUrl}/c/${categoryPath}`;
}

export function guidesProductUrl(artId: string) {
  return `${guidesBaseUrl}/p/${artId}`;
}

export const faqBaseUrl = "/faq";
export const productTypeBaseUrl = supportBaseUrl + "/products";
export const mapBaseUrl = "/map";

export function toAbsoluteUrl(relativePath: string, lang?: Language) {
  const url = new URL(relativePath, document.baseURI);
  if (lang) {
    url.pathname = `/${lang}${url.pathname}`;
  }
  return url.href;
}
