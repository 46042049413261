import { initializeApp } from "firebase/app";

const config = {
  apiKey: "AIzaSyDXGkLSZw2KlvG9Ogy9gezUvwhfHwtVo2E",
  authDomain: "bjelin.firebaseapp.com"
};

let app = undefined;

const Singleton = {
  getApp: () => {
    if (app === undefined) app = initializeApp(config);
    return app;
  }
};

// Disallow new properties
Object.freeze(Singleton);

export default Singleton;
