import i18n from "i18next";
import BackendAdapter from "i18next-multiload-backend-adapter";
import HTTP from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import { baseURL } from "../services/apiService";

const debug = false;
const ns = ["Bjelin", "products", "countries", "specifications"];
export const defaultNS = "Bjelin";

i18n
  .use(BackendAdapter)
  .use(initReactI18next) // bind react-i18next to the instance
  .init({
    lng: "en",
    fallbackLng: "en",
    lowerCaseLng: true,
    debug,
    keySeparator: false,
    ns,
    defaultNS,

    interpolation: {
      escapeValue: false // not needed for react!!
    },

    react: {
      useSuspense: true,
      omitBoundRerender: false
    },

    backend: {
      backend: HTTP,
      backendOption: {
        loadPath: `${baseURL}/locale?lng={{lng}}&ns={{ns}}`,
        crossDomain: true
      }
    }
  });

export default i18n;
