import { useTranslation } from "react-i18next";
import { defaultNS } from "../config/i18next";
import { UserContext, useUser } from "../functional-components/UserContext";
import { useContext, useMemo } from "react";
import { TFunction } from "i18next";

export type TDictionary<T = unknown> = { [key: string]: T };

const options = { useSuspense: true };
function useTWithLogging(t: TFunction, NS: string): TFunction {
  const { admin } = useUser();
  const userContext = useContext(UserContext);

  const tWithLogging = useMemo(
    () => (key: string, options: TDictionary) => {
      //if (admin) console.log("translation:", "Bjelin", key);
      return admin && userContext?.translationAdminModeOn
        ? "[[" + NS + " | " + key + "]]"
        : t(key, options);
    },
    [admin, userContext?.translationAdminModeOn, NS, t]
  );
  return tWithLogging as TFunction;
}

export function useI18n() {
  return useTranslation(defaultNS, options).i18n;
}

export function useBjelinTranslations(): TFunction {
  const t = useTranslation(defaultNS, options).t;
  return useTWithLogging(t, defaultNS);
}

export function useProductTranslations() {
  const t = useTranslation("products", options).t;
  return useTWithLogging(t, "products");
}

export function useSpecsTranslation() {
  const ts = useTranslation("specifications", options).t;
  const tsFunction = useMemo(
    () => (spec: string, value?: string) => {
      return (
        value
          ? ts(spec + (value ? "." + value : ""), /*default value:*/ value)
          : ts(spec)
      ) as string;
    },
    [ts]
  );
  return tsFunction;
}

export function useUnitTranslation() {
  const ts = useTranslation("specifications", options).t;
  const tsFunction = useMemo(
    () => (unit: string) => {
      return ts("unit." + unit, /*default value:*/ unit) as string;
    },
    [ts]
  );
  return tsFunction;
}

export function useSpecsGroupTranslation() {
  const ts = useTranslation("specifications", options).t;
  const tsFunction = useMemo(
    () => (group: string) => {
      return ts("group." + group, /*default value:*/ group) as string;
    },
    [ts]
  );
  return tsFunction;
}

export function useCountriesTranslations() {
  return useTranslation("countries", options).t;
}

export function useTagsTranslations() {
  return useTranslation("tags", options).t;
}
