const errorCodes = {
  ACTION_CODE_INVALID: "error.firebase.auth.invalid-action-code",
  EMAIL_INVALID: "error.firebase.invalid-email",
  EMAIL_NOT_REPEATED: "error.email.addresses.dont.conform",
  FIELD_IS_REQUIRED: "error.field.is.required",
  FILE_TOO_LARGE: "error.file.too.large",
  FORM_CONTAINS_ERRORS: "error.form.contains.errors",
  GET_ADDRESS_WITH_PERSONNUMMER_FAILED: "error.get.address.with.personnummer",
  PERSONNUMMER_INVALID: "error.personnummer.invalid",
  UNKNOWN: "error.unknown",
  WRONG_CURRENT_PASSWORD: "error.wrong.current.password",
  DATE_INVALID: "error.date.invalid",
  REQUEST_TIMEOUT: "error.request.timeout",
  TERMS_NOT_ACCEPTED: "error.terms-not-accpted",
  LOGIN_FAILED: "login.failed",
  ERROR_GET_USER: "error.get.user"
};

export default errorCodes;
