import { ProductCategory } from "../types/ProductCategory";
import { useMemo } from "react";
import { enhanceSettingsProductCategories } from "../utils/CockpitUtils";
import { useLocSettings } from "./SettingsHooks";
import { Language } from "../config/languages";
import { useQuery } from "react-query";
import apiService from "../services/apiService";

export function useProductCategories(): ProductCategory[] {
  const settings = useLocSettings();
  const productCategories = useMemo(() => {
    const clonedSettings = JSON.parse(JSON.stringify(settings));
    enhanceSettingsProductCategories(clonedSettings, true);
    return clonedSettings.product_categories;
  }, [settings]);
  return productCategories;
}

export function useAllProductCategories(lang: Language): ProductCategory[] {
  const { data: productCategories } = useQuery<ProductCategory[]>(
    ["useAllProductCategories", lang],
    async () => await getProductCategories(lang, false)
  );
  return productCategories || [];
}

export async function getProductCategories(
  lang: Language,
  onlyEnabledOnCurrentSite = true
): Promise<ProductCategory[]> {
  const settings = await apiService.loadSettings(lang);
  enhanceSettingsProductCategories(settings, onlyEnabledOnCurrentSite);
  return settings.product_categories as ProductCategory[];
}
